<template>
  <v-row class="ma-0">
   <!-- welcome -->
   <v-col cols="6">
      <ax-welcome />
    </v-col>
    <!-- end welcome -->
    <v-col class="container-video" cols="6" style="align-items: center;">
      <v-col cols="8" class="mx-auto">
        <v-card class="mx-auto text-left pa-5 roundedXL" :max-width="450" flat align="center" outlined justify="center">
          <v-card-text class="pa-0 px-2">
            <v-col class="text-center pa-0 mt-2 mb-6">
              <img :src="require(`@/assets/appBrand--fc-full${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" width="211">
              <span class="mt-1 d-block body-1 fontBody--text font-weight-bold">Restablecer contraseña</span>
              <span class="mt-1 d-block body-1 fontBody--text">Escribe tu nueva contraseña para iniciar sesión.</span>
            </v-col>
            <span class="text-body-2 text-fontBody mt-3">Contraseña</span>
            <v-text-field class="icon-field" v-model="user.new_password1" @keyup.enter="resetPassword" :error="$v.user.new_password1.$error" @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'user.new_password1' : 'password'" outlined hide-details required single-line dense maxlength="20" autocomplete="new-password" />
            <span class="d-block mt-5 text-body-2 text-fontBody">Contraseña</span>
            <v-text-field class="icon-field" v-model="user.new_password2" @keyup.enter="resetPassword" :error="$v.user.new_password2.$error" @click:append="show = !show" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'user.new_password2' : 'password'" outlined :hide-details="$v.user.new_password2.sameAsPassword" persistent-hint required single-line dense maxlength="20" autocomplete="new-password" hint="El password debe ser igual." />
            <v-btn class="mt-8 mb-2"  @click="resetPassword" :loading="createLoader" color="primary" dark block>Restablecer contraseña</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import AxWelcome from '@/components/commons/AxWelcome'

export default {
  components: {
    AxWelcome
  },
  data: () => ({
    user: {
      uid: null,
      token: null,
      new_password1: '',
      new_password2: ''
    },
    createLoader: false,
    show: false,
    show1: false
  }),
  created () {
    this.user.uid = this.$route.params.uid
    this.user.token = this.$route.params.token
  },
  methods: {
    resetPassword () {
      this.$v.user.$touch()
      if (this.$v.user.$invalid) {
        return false
      }
      this.createLoader = true
      this.$store.dispatch('auth/PASSWORD_RESET_CONFIRM', {
        uid: this.user.uid,
        token: this.user.token,
        new_password1: this.user.new_password1,
        new_password2: this.user.new_password2
      })
      .then(() => {
        this.$dialog.message.info('Se ha restablecido la contraseña, inicia sesión para continuar.')
        this.$router.push({ name: 'AuthSignIn' })
      })
      .finally(() => {
        this.createLoader = false
      })
    }
  },
  validations: {
    user: {
      new_password1: {
        required,
        minLength: minLength(8)
      },
      new_password2: {
        sameAsPassword: sameAs('new_password1')
      }
    }
  }
}
</script>